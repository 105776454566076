import Axios from 'axios'
// 新增投诉
export const saveComplaint = (data) => Axios({
    url: '/zuwu-api/web/serve/save',
    method: 'post',
    dataType: 'json',
    data
});

// 官网-选择设备
export const selectEquipment = (params) => Axios({
    url: '/zuwu-api/web/serve/selectEquipment',
    method: 'get',
    params
});
// 二维码
export const getCaptcha = (params) => Axios({
    url: '/zuwu-api/web/serve/captcha',
    method: 'get',
    params
});
// 新增助贷请求
export const consultSubmit = (data) => Axios({
    url: '/zuwu-api/web/serve/consultSubmit',
    method: 'post',
    dataType: 'json',
    data
});