<template>
  <div class="enterpriseAssistance">
    <img v-if="showForm" class="bg" src="@/assets/imgs/assist.png">
    <img v-else class="bg" src="@/assets/imgs/assist1.png">
<!--    <div class="header">-->
<!--      <div class="title">高享汇</div>-->
<!--      <el-button v-if="!showForm" round @click="application">申请体验</el-button>-->
<!--    </div>-->
    <div v-if="!showForm" class="mainBox">
      <div class="big">企业助贷咨询</div>
      <div class="mini">数字金融服务</div>
    </div>
    <div v-if="!showForm" class="consult">
      <el-button round @click="application">立即咨询</el-button>
    </div>
    <div v-if="showForm" class="application">
      <div class="title">申请体验</div>
      <div class="tips">请留下联系方式，我们会尽快联系您</div>
      <el-form size="medium" :model="applicationForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="enterpriseName">
          <el-input  placeholder="公司名称" v-model="applicationForm.enterpriseName"></el-input>
        </el-form-item>
        <el-form-item prop="needDescribe">
          <el-input  type="textarea" :rows="2"  placeholder="需求描述" v-model="applicationForm.needDescribe"></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <el-input  placeholder="联系人" v-model="applicationForm.name"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input  placeholder="手机" v-model="applicationForm.phone"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input  placeholder="邮箱" v-model="applicationForm.email"></el-input>
        </el-form-item>
        <el-form-item prop="verCode">
          <div class="image">
            <el-input v-model="applicationForm.verCode" placeholder="请输入图形验证码" maxlength="5" @keyup.enter.native="submit"></el-input>
            <div class="image-code" :style="'backgroundImage: url(' + codeeImg + ')'" @click="refreshCode"></div>
          </div>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button size="medium" type="primary" @click="submit" style="width: 100%">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {validatePhone,validateEmail} from "@/util/validator";
import {getCaptcha,consultSubmit} from '@/api/serve/index'

export  default {
  name:'enterpriseAssistance',
  data(){
    return{
      showForm:false,
      codeeImg:'',
      applicationForm:{},
      rules:{
        name: [{ required: true, trigger: 'blur', message: '请输入姓名'}],
        phone: [{ required: true, trigger: 'blur', message: '请输入正确的手机号', validator: validatePhone}],
        email: [{ required: true, trigger: 'blur', message: '请输入正确的邮箱' ,validator: validateEmail}],
        enterpriseName: [{ required: true, trigger: 'blur', message: '请输入公司名称'}],
        needDescribe: [{ required: true, trigger: 'blur', message: '请输入需求描述'}],
        verCode: [{ required: true, trigger: 'blur', message: '请输入验证码'}],
      }
    }
  },
  created() {
    this.refreshCode()
  },
  methods:{
    application(){
      this.showForm = true
    },
    refreshCode() {
      getCaptcha().then((res) => {
        const data = res.data
        this.applicationForm.key = data.key
        this.codeeImg = data.image
      })
    },
    submit(){
      this.$refs.ruleForm.validate(valid=>{
        if(valid){
          console.log(this.applicationForm)
          consultSubmit(this.applicationForm).then(()=>{
            console.log('申请成功')
            this.$router.push('/home')
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.enterpriseAssistance{
  height: 950px;
  margin-bottom: 30px;
  position: relative;
  .bg{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .header{
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    line-height: 100px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    .title{
      font-size: 35px;
    }
    ::v-deep .el-button{
      background: #524ff7;
      color: #fff;
      border: none;
    }
  }
  .mainBox{
    color: #fff;
    position: absolute;
    left: 12%;
    bottom: 50%;
    text-align: center;
    .big{
      font-size: 100px;
    }
    .mini{
      font-size: 60px;
    }
  }
  .consult{
    position: absolute;
    bottom: 35%;
    left: 21%;
    ::v-deep .el-button{
      width: 200px;
      height: 50px;
      color: #1b1f23;
      border-radius: 50px;
      font-size: 25px;
      border: none;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .application{
    position: absolute;
    right: 20%;
    top: 20%;
    border: 2px solid #f3f3f3;
    width: 400px;
    padding: 40px;
    background-color: #fff;
    border-radius: 5px;
    ::v-deep .el-input__inner{
      background: #f7f7f7;
    }
    .title{
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .tips{
      font-size: 20px;
      margin-bottom: 10px;
    }
    .image-code {
      width: 100px;
      height: 34px;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;
      position: absolute;
      right: 0px;
      top: 1px;
    }
  }
}

</style>